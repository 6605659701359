import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../../components/Layout";
import Section from "../../components/Section"
import SectionBanner from "../../components/SectionBanner";
import SectionOverview from "../../components/SectionOverview"
import People from "../../components/People"
import Seo from "../../components/Seo";
import Image from "../../components/Image"
import ImageSVG from "../../components/ImageSVG"
import Button from "../../components/Button"
import Icon from "../../components/Icon"
import TalentedPeopleBGImage from '../../images/about/about--talented-people.jpg'


function PhilosophyPillars(props) {
  return props.pillars.map((pillar, i) => {
    return (
      <div className="about-philosophy-pillar" key={i}>
        <div className="about-philosophy-pillar__image">
          <Image src={`${"about/" + pillar.image}`} alt={pillar.imageAlt} />
        </div>

        <h3 className="about-philosophy-pillar__title">{pillar.title}</h3>

        <p className="about-philosophy-pillar__description">
          {pillar.description}
        </p>
      </div>
    )
  })
}


export default function AboutLanding() {

  const data = useStaticQuery(graphql`
    query philosophyPillars {
      allPhilosophyPillarsJson {
        nodes {
          image
          imageAlt
          title
          description
        }
      }
    }
  `)

  const philosophyPillaryData = data.allPhilosophyPillarsJson.nodes

  return (
    <Layout id="about-landing">
      <Seo
        title="About"
        description="About Us"
        relPath={"/about/"}
      />
      <div className="page--about">

        <SectionBanner
          id={"about-hero"}
          bgImage={"about/about--hero.jpg"}
          bgTextShade={true}
          title="About Bixal"
        />


        <Section
          id="about-intro"
          constrain
          indent
          spacing={120}
        >
          <SectionOverview divider>
            <h2>Bixal is a mission-driven organization determined to have a positive impact on the lives of people everywhere.</h2>
          </SectionOverview>

          <p>We do this by partnering with leading federal agencies to conceive and create powerful data-driven customer experiences to better serve the American public and communities around the world.</p>
          <p>Bixal is more than an organization. Bixal is a methodology. It’s a people-absolutely-first approach to solving complex organizational challenges, balancing cutting-edge technical chops with a deep sense of empathy and understanding.</p>
        </Section>


        <div className="about-b-banner">
          <div
            className="about-b-banner__visual-accent"
            role="presentation"
            aria-hidden="true"
          >
            <ImageSVG
              src="overlays/b-orange.svg"
              alt="orange Bixal B logo"
            />
          </div>

          <div className="about-b-banner__banner">
            <Image
              src="about/about--b-banner.jpg"
              alt="A group of coworkers talking at in a break room."
            />
          </div>
        </div>


        <Section
          id="about-meet-team-bixal"
          constrain
          indent
          spacing={80}
        >
          <SectionOverview
            align="center"
            divider>
            <h2>Meet Team Bixal</h2>
          </SectionOverview>

          <div className="about-meet-team-bixal__description">
            <p>An organization is only as effective as its people. Our people are our lifeblood—the heart and soul behind every project we take on and every impact we make.</p>
          </div>

          <div id="team-members">
            <People />
          </div>
        </Section>


        <Section
          id="about-what-we-believe"
          constrain
          indent
          spacing={80}
        >
          <SectionOverview
            align="center"
            divider>
            <h2>Our Core Values</h2>
          </SectionOverview>

          <div className="about-what-we-believe__description">
            <p>Bixal’s principles are expressed in four core organizational values that shape our culture and guide our work every day.</p>
          </div>

          <div className="about-philosophy-pillars">
            <PhilosophyPillars pillars={philosophyPillaryData} />
          </div>
        </Section>


        <Section
            id={"about-talented-people"}
            spacing={360}
            bgColor={"#7d0196"}
            bgTextShade={false}
            bgImage={TalentedPeopleBGImage}
            indent={true}
            constrain
          >
            <div className="about-talented-people__content">
              <SectionOverview divider>
                <h2>We're always looking for talented people.</h2>
              </SectionOverview>

              <p>If you share our sense of purpose and are interested in
                exploring opportunities with Bixal, drop us a line or check
                out our careers page.</p>

              <div className="about-talented-people__cta">
                <Link to="/careers/">
                  <Button type={"link"}>
                    Learn More <Icon type={"rightArrow"} color="#fff"></Icon>
                  </Button>
                </Link>
              </div>
            </div>
        </Section>

      </div>
    </Layout>
  )
}
