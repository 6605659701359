import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PersonProfileImage from './PersonProfileImage'
import Icon from './Icon'
import Section from './Section'
// import DropDown from "./DropDown" //Todo: move this into omposition
import { animateScroll as scroll } from 'react-scroll'
import styled, { keyframes } from 'styled-components'

const animateIn = keyframes`
  from {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const AnimatedListItem = styled.li`
  display: block;
  animation-name: ${props => (props.animate === true ? animateIn : null)};
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
`

export default function People() {
  const data = useStaticQuery(graphql`
  query {
    peopleimages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "bixal-people" }
      }
    ) {
      edges {
        node {
          relativePath
        }
      }
    }
    allBixalPeopleJson {
      nodes {
        jsonId
        name
        jobTitle
        department
      }
    }
  }
  `)

  const [peopleData, setPeopleData] = useState([])
  const [leadershipBottom, setLeadershipBottom] = useState([])
  const [leadershipTop, setLeadershipTop] = useState([])

  useEffect(() => {
    var leadership = [...data.allBixalPeopleJson.nodes].filter(p => {
      return p.department === 'Leadership'
    })

    var leadershipTop = leadership.filter(p => {
      return p.jsonId === 'carla-briceno' || p.jsonId === 'joselo-briceno' || p.jsonId === 'katie-cella'
    })

    var leadershipBottom = leadership.filter(p => {
      return p.jsonId !== 'carla-briceno' && p.jsonId !== 'joselo-briceno' && p.jsonId !== 'katie-cella'
    })
    leadershipTop.push(leadershipTop.splice(1, 1)[0])

    var validProfiles = [...data.allBixalPeopleJson.nodes].filter(p => {
      var imageExists = data.peopleimages.edges.find(({ node }) => `bixal-people/${p.jsonId}.jpg` === node.relativePath)
      if (imageExists !== undefined)
        return p
    })

    setPeopleData(
      validProfiles.map(p => {
        p.animate = Boolean(true)
        return p
      })
    )

    setLeadershipBottom(
      leadershipBottom.map(p => {
        p.animate = Boolean(true)
        return p
      })
    )

    setLeadershipTop(
      leadershipTop.map(p => {
        p.animate = Boolean(true)
        return p
      })
    )

  }, [data])
  const departments = {
    all: 'All',
    leadership: 'Leadership',
    international: 'International',
    design: 'Design',
    data: 'Data',
    growth: 'Growth',
    communications: 'Communications',
    technology: 'Technology',
    delivery: 'Delivery',
    learning: 'Learning',
    operations: 'Operations',
  }

  let defaultOption = Object.keys(departments)[0]

  useEffect(() => {
    const validParams = ['all', 'leadership', 'international', 'design', 'data', 'growth', 'communications', 'technology', 'delivery', 'learning', 'operations']
    const windowUrl = window.location.search;
    const linkParam = Object.fromEntries(new URLSearchParams(windowUrl)).filter

    if (linkParam === undefined | !validParams.includes(linkParam)) {
      setPeopleFilter("all")
    } else {
      setPeopleFilter(linkParam)
    }
  }, [])

  const [peopleFilter, setPeopleFilter] = useState(() => defaultOption)

  const initDisplayLength = 20

  const filterPeople = React.useCallback(
    value => {
      if (peopleFilter === 'all') {
        return value
      }
      return value.department.toLowerCase() === peopleFilter
    },
    [peopleFilter]
  )

  const [people, setPeople] = useState(null)

  useEffect(() => {
    setPeople(peopleData.filter(filterPeople).slice(0, initDisplayLength))
  }, [peopleData, filterPeople, initDisplayLength])

  function jumpTo(id) {
    const jumpElement = document.getElementById(id)
    const elementPosition =
      jumpElement.getBoundingClientRect().top + window.scrollY

    if (jumpElement !== null) {
      scroll.scrollTo(`${elementPosition}`, {
        duration: 1000,
        smooth: true,
      })
    }
  }

  const updatePeople = useMemo(() => {
    const handleAdd = () => {
      const newArray = [...peopleData].filter(filterPeople)
      let focusItem = newArray[people.length - 1]
      jumpTo(`${focusItem.jsonId}`)
      //removing slice to show all
      // let count = isMobile === true ? 6 : 10
      // let newPeople = newArray.slice(people.length, people.length + count)

      let newPeople = newArray.slice(20, newArray.length)

      let otherPeople = people.map(p => {
        return { ...p, animate: Boolean(false) }
      })

      setPeople([otherPeople, newPeople].flat())
    }

    return handleAdd
  }, [people, peopleData, filterPeople])

  // filter our view when a selection is made
  const [openDrawer, setOpenDrawer] = useState(Boolean())

  const handleDropDown = value => {
    return setOpenDrawer(value)
  }

  const handleSelect = React.useCallback(
    e => {
      setPeopleFilter(e?.target.value)
      setOpenDrawer(!openDrawer)
      const newArray = [...peopleData].filter(filterPeople)
      setPeople(newArray.slice(0, initDisplayLength))
    },
    [openDrawer, filterPeople, peopleData]
  )

  useEffect(() => {
    setOpenDrawer(false)
  }, [])

  const Person = React.useCallback(({ children }) => {
    return (
      <AnimatedListItem
        className="person"
        key={children.jsonId}
        id={children.jsonId}
        animate={children.animate}
      >
        <Link to={`/about/${children.jsonId}/`} className="person__link">
          <div className="person__image-container">
            <div className="person__image-inner">
              <PersonProfileImage
                className="person__image"
                src={`bixal-people/${children.jsonId}.jpg`}
                alt={children.name}
              />

              <div className="person__overlay">
                <div type="link" className="person__overlay-label">
                  View All
                  <Icon type={'rightArrow'} color={'#fff'}></Icon>
                </div>
              </div>
            </div>
          </div>

          <div className="person__details">
            <h3 className="person__name">{children.name}</h3>

            <div className="person__job-title">{children.jobTitle}</div>
          </div>
        </Link>
      </AnimatedListItem>
    )
  }, [])

  const DropDown = useCallback(
    ({ options, defaultOption }) => {
      return (
        <div className="dropdown">
          <div
            data-test-id="filter-by-select"
            className={`dropdown-select ${openDrawer === true ? 'toggled' : ''
              }`}
            onClick={() => handleDropDown(!openDrawer)}
            onKeyPress={() => handleDropDown(!openDrawer)}
            role="button"
            tabIndex={0}
          >
            Filter By{'   '}
            <Icon type={'carrotDown'} color={'#000'}></Icon>
          </div>
          <div
            data-test-id="filter-by-content"
            className={`dropdown-content ${openDrawer === true ? 'open' : ''}`}
          >
            <div name="options" id="options" onChange={handleSelect}>
              {Object.keys(options).map(option => (
                <div className="dropdown-option" key={option}>
                  <input
                    type="radio"
                    name="radio"
                    id={option}
                    value={option}
                    defaultChecked={option === defaultOption}
                  />
                  <label
                    data-test-id={`filter-by-label${options[option]}`}
                    className="container"
                    htmlFor={option}
                  >{option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    },
    [openDrawer, handleSelect]
  )

  return (
    <Section spacing={0} id={'bixal-people'}>

      <DropDown
        options={departments}
        defaultOption={peopleFilter}
        onClick={() => setOpenDrawer(!openDrawer)}
        onKeyDown={() => setOpenDrawer(!openDrawer)}
        onChange={e => handleSelect(e)}
      />

      {peopleFilter === 'leadership' ? (
        <div><ul className="people-list execs-list">
          {leadershipTop?.map((p, i) => (
            <Person key={i}>{p}</Person>
          ))}
        </ul>

          <ul className="people-list">
            {leadershipBottom?.map((p, i) => (
              <Person key={i}>{p}</Person>
            ))}
          </ul></div>) : (
        <div><ul className="people-list">
          {people?.map((p, i) => (
            <Person key={i}>{p}</Person>
          ))}
        </ul></div>
      )}
      {people?.length > initDisplayLength - 1 &&
        people?.length < [...peopleData].filter(filterPeople).length ? (
        <div className="people-list__view-more">
          <button
            className="button bixal-button button--inverted"
            onClick={updatePeople}
          >
            <div data-test-id="view-more" className="bixal-button-content">
              <div>View All</div>
            </div>
          </button>
        </div>
      ) : null}
    </Section>
  )
}